/**
 * Created by stapletont on 11/06/2015.
 */

define('urlReader', function() {

  

  /**
   * Url Reader
   * @namespace
   */
  var urlReader = {

    // Deconstruct from the URL
    decoupler: {
      variableQuery: '',
      categoryValueSeparator: '',
      categorySeparator: '',
      segmentSeparator: ''
    },

    // Build for the API
    constructor: {
      variableQuery: '',
      categoryValueSeparator: '',
      categorySeparator: '',
      segmentSeparator: ''
    },

    /**
     * Get all categories and transform into multidimensional array for construction
     * @param uriSegment
     * @returns {Array}
     */
    getCategories: function(uriSegment) {
      var categories = uriSegment.split(urlReader.decoupler.categorySeparator);
      return categories.map(function(category) {
        var categoryAndSegments = category.split(urlReader.decoupler.categoryValueSeparator);
        if (urlReader.decoupler.categoryValueSeparator === urlReader.decoupler.segmentSeparator) {
          return [categoryAndSegments[0], categoryAndSegments.slice(1, categoryAndSegments.length)];
        }
        return [categoryAndSegments[0], urlReader.getSegments(categoryAndSegments[1])];
      });
    },

    /**
     * Return all segments from the passed array
     * @param uriSegment
     * @returns {Array}
     */
    getSegments: function(uriSegment) {
      var result = [];

      // TODO make decoupler and constructor segments equal to encoded aliases as well as plain text
      if (uriSegment.indexOf('%7C') > -1) {
        result = uriSegment.split('%7C');
      } else {
        result = uriSegment.split(urlReader.decoupler.segmentSeparator);
      }
      return result;
    },

    /**
     * Deconstruct and return the updated url segments
     * @param action
     * @param segment
     * @param category
     * @param [customUrl]
     * @returns {Object}
     */
    deconstructGet: function(action, segment, category, customUrl) {
      var urlVariables = [];
      var url = customUrl || window.location.href;
      if (url.split(urlReader.decoupler.variableQuery)[1]) {
        var urlSegments = url.split(urlReader.decoupler.variableQuery);
        urlVariables = urlSegments[1];
        url = urlSegments[0];
        if (category) {
          urlVariables = urlReader.getCategories(urlVariables);
        } else {
          urlVariables = urlReader.getSegments(urlVariables);
        }
        urlReader.updateSegment(action, urlVariables, segment, category);
        urlVariables = urlReader.constructParameters(urlVariables, category);
      } else {
        urlVariables = urlReader.directUpdate(action, segment, category, urlVariables);
      }
      return urlReader.updatedUrl(url, urlVariables);
    },

    /**
     * Update the parameters directly
     * @param action
     * @param segment
     * @param category
     * @param urlVariables
     * @returns {String}
     */
    directUpdate: function(action, segment, category, urlVariables) {
      if (action === 'remove') {
        return '';
      }

      if (category) {
        urlVariables = category + urlReader.constructor.categoryValueSeparator + segment;
      } else {
        urlVariables = segment;
      }
      return urlVariables;
    },

    /**
     * Return the API values and the URL values
     * @param url
     * @param urlVariables
     * @returns {Object}
     */
    updatedUrl: function(url, urlVariables) {
      var returnedUrlProperties = {
        api: {
          url: url,
          urlVariables: urlVariables
        }
      };

      if (urlVariables === '') {
        returnedUrlProperties.window = returnedUrlProperties.api;
        return returnedUrlProperties;
      }

      var decoupler = urlReader.decoupler.variableQuery;
      if (url.indexOf(decoupler) > -1) {
        decoupler = '';
      }

      returnedUrlProperties = {
        api: {
          url: url + urlReader.constructor.variableQuery + urlVariables,
          urlVariables: urlReader.constructor.variableQuery + urlVariables
        },
        window: {
          url: url + decoupler + urlVariables,
          urlVariables: decoupler + urlVariables
        }
      };
      return returnedUrlProperties;
    },

    /**
     * Update the segment
     * @param action
     * @param urlVariables
     * @param segment
     * @param category
     */
    updateSegment: function(action, urlVariables, segment, category) {
      if (category) {
        for (var i = 0, urlVariablesLength = urlVariables.length; i < urlVariablesLength; i++) {
          var currentCategory = urlVariables[i];
          if (currentCategory[0] === category) {
            var categoryParameters = currentCategory[1];
            urlReader.modifySegment(categoryParameters, segment, action);
            if (categoryParameters.length === 0 || categoryParameters[0] === '') {
              urlVariables.splice(i, 1);
            }
            return;
          }
        }
        if (action === 'remove') {
          return;
        }
        urlVariables.push([category, [segment]]);
        return;
      }
      urlReader.modifySegment(urlVariables, segment, action);
    },

    /**
     * Run the appropriate action for the segment
     * @param level
     * @param segment
     * @param action
     */
    modifySegment: function(level, segment, action) {
      if (action === 'remove') {
        urlReader.removeSegment(level, segment);
      }

      if (action === 'replace') {
        level.splice(0, level.length);
        level.push(segment);
      }

      if (action === 'add') {
        level.push(segment);
      }
    },

    /**
     * Remove segment
     * @param currentCategory
     * @param segment
     */
    removeSegment: function(currentCategory, segment) {
      for (var i = 0, segmentsLength = currentCategory.length; i < segmentsLength; i++) {
        if (currentCategory[i] === segment) {
          currentCategory.splice(i, 1);
          return;
        }
      }
    },

    /**
     * Build url parameters
     * @param urlVariables
     * @param {boolean} [category]
     * @returns {string}
     */
    constructParameters: function(urlVariables, category) {
      if (category) {
        return urlVariables.map(function(category) {
          return category[0] + urlReader.constructor.categoryValueSeparator + category[1].join(urlReader.constructor.segmentSeparator);
        }).join(urlReader.constructor.categorySeparator);
      }
      return urlVariables.join(urlReader.constructor.segmentSeparator);
    }
  };

  return urlReader;
});
